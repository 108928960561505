import {createSlice} from "@reduxjs/toolkit";

const taxiSlice = createSlice({
    name: "taxi",
    initialState: {
        startAddress: null,
        startLocation: null,

        endAddress: null,
        endLocation: null,
        currentLocation: null,

        startAddressEntrance: null,
        paymentMethod: "cash",
        babyChair: false,
        animalDrive: false,
        emptyTrunk: false,
        helpDriver: false,
        comment: null,
        coupon: null,
        routeType: 'driving',
        osrmServer: 'osrm.jetcab.ru',
        // osrmProfile: 'routed-car',
        osrmProfile: null, // изменим значение по умолчанию на null
    },
    reducers: {
        setStartAddress: (state, action) => {
            state.startAddress = action.payload;
        },
        setStartLocation: (state, action) => {
            state.startLocation = action.payload;
        },
        setEndAddress: (state, action) => {
            state.endAddress = action.payload;
        },
        setEndLocation: (state, action) => {
            state.endLocation = action.payload;
        },
        setBabyChair: (state, action) => {
            state.babyChair = action.payload;
        },
        setAnimalDrive: (state, action) => {
            state.animalDrive = action.payload;
        },
        setEmptyTrunk: (state, action) => {
            state.emptyTrunk = action.payload;
        },
        setHelpDriver: (state, action) => {
            state.helpDriver = action.payload;
        },
        setCurrentLocation: (state, action) => {
            state.currentLocation = action.payload;
        },
        setPaymentMethod: (state, action) => {
            state.paymentMethod = action.payload
        },
        setStartAddressEntrance: (state, action) => {
            state.startAddressEntrance = action.payload
        },
        setComment: (state, action) => {
            state.comment = action.payload
        },
        setCoupon: (state, action) => {
            state.coupon = action.payload
        },
        setRouteType: (state, action) => {
            state.routeType = action.payload;
        },
        setOsrmServer: (state, action) => {
            state.osrmServer = action.payload;
        },
        setOsrmProfile: (state, action) => {
            state.osrmProfile = action.payload;
        }
    },
});

export default taxiSlice;