import L from "leaflet";
import "leaflet-routing-machine";
import { store } from "../store/store";

export const routingCreateControl = (startLocation, endLocation) => {
    const state = store.getState().taxi;
    console.log('Состояние при построении маршрута:', {
        routeType: state.routeType,
        osrmServer: state.osrmServer,
        osrmProfile: state.osrmProfile
    });

    const routeType = state.routeType;
    const osrmServer = state.osrmServer;
    const osrmProfile = state.osrmProfile;

    const serviceUrl = `https://${osrmServer}/${osrmProfile}/route/v1`;
    console.log('Итоговый URL для маршрута:', serviceUrl);

    return L.Routing.control({
        waypoints: [
            L.latLng(startLocation.latitude, startLocation.longitude),
            L.latLng(endLocation.latitude, endLocation.longitude),
        ],
        language: "ru",
        draggableWaypoints: false,
        routeWhileDragging: false,
        fitSelectedRoutes: true,
        addWaypoints: false,
        show: false,
        lineOptions: {
            styles: [{color: "rgb(0, 136, 204)", opacity: 0.8, weight: 9}],
        },
        router: new L.Routing.OSRMv1({
            serviceUrl: serviceUrl,
            profile: routeType || 'driving',
            routingOptions: {
                alternatives: false,
                steps: false,
            },
            useHints: false,
        }),
        createMarker: function () {
            return null;
        },
    });
}