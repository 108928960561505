import { useMapEvents } from "react-leaflet";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import {DADATA_TOKEN, GET_MAP_CENTER, GET_ROUTE_TYPE, GET_OSRM_SERVER, GET_OSRM_PROFILE} from "../const";
import {useTaxiActions, useUIActions} from "../store/useActions";
import {useEffect, useState} from "react";
import axios from "axios";
import {useUISelector} from "../store/useSelectors";
import {nominatimReverse} from "../api/nominatim";
import {nominatimProcessResult} from "../services/nominatim";

export const LocationPicker = (props) => {
    const { pickLocationField } = useUISelector();
    const { setCenterPosition } = useUIActions();
    const { setStartAddress, setEndAddress, setStartLocation, setEndLocation, setRouteType, setOsrmServer, setOsrmProfile } =
        useTaxiActions();

    useEffect(() => {
        // Загружаем центр карты
        axios.get(GET_MAP_CENTER)
            .then(response => {
                var payload = response.data
                if (!payload) return;
                var coords = payload.web_app_map_center.coordinates
                setCenterPosition(coords.reverse())
            })
            .catch(error => {
                console.error('Ошибка получения центра карты:', error)
            });

        // Загружаем тип маршрута
        axios.get(GET_ROUTE_TYPE)
            .then(response => {
                const payload = response.data;
                console.log('Ответ сервера тип маршрута:', payload);
                if (!payload) return;
                setRouteType(payload.route_type);
            })
            .catch(error => {
                console.error('Ошибка получения типа маршрута:', error)
            });

        // Загружаем OSRM сервер
        axios.get(GET_OSRM_SERVER)
            .then(response => {
                const payload = response.data;
                console.log('Ответ сервера OSRM сервер:', payload);
                if (!payload) return;
                setOsrmServer(payload.osrm_server);
            })
            .catch(error => {
                console.error('Ошибка получения OSRM сервера:', error)
            });

        // Загружаем OSRM профиль
        axios.get(GET_OSRM_PROFILE)
            .then(response => {
                const payload = response.data;
                console.log('Ответ сервера OSRM профиль:', payload);
                if (!payload) return;
                const profile = payload.osrm_type.replace(/^\/|\/$/g, '');
                console.log('Обработанный OSRM профиль:', profile);
                setOsrmProfile(profile);
            })
            .catch(error => {
                console.error('Ошибка получения OSRM профиля:', error)
            });
    }, []);

    const mapEvents = useMapEvents({
        click(event) {
            if (!pickLocationField) return;

            const coords = event.latlng;
            try {
                nominatimReverse(coords.lat, coords.lng)
                    .then((response) => response.data)
                    .then((resultJson) => {
                        if (!resultJson) return;
                        const result = nominatimProcessResult(resultJson);
                        if (pickLocationField === "start") {
                            setStartAddress(result.set_name);
                            setStartLocation({
                                latitude: parseFloat(coords.lat),
                                longitude: parseFloat(coords.lng),
                            });
                        } else {
                            setEndAddress(result.set_name);
                            setEndLocation({
                                latitude: parseFloat(coords.lat),
                                longitude: parseFloat(coords.lng),
                            });
                        }
                    }).catch(error => toast.error("Не удалось определить адрес"));
            } catch {
                toast.error("Не удалось определить адрес")
            }
        },
    });

    return null;
};