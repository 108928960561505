export const DADATA_TOKEN               = process.env.REACT_APP_DADATA_TOKEN;
export const API_URL                    = process.env.REACT_APP_API_URL;
export const YANDEX_METRIKA_ID          = process.env.REACT_APP_YANDEX_METRIKA_ID;
export const GET_MAP_CENTER             = API_URL + "map-center/";
export const GET_ROUTE_TYPE             = API_URL + "getRouteType/";
export const GET_OSRM_SERVER = API_URL + "getOsrmServer/";
export const GET_OSRM_PROFILE = API_URL + "getOsrmType/";
export const DEFAULT_CENTER_POSITION    = [50.290130, 127.527183]
export const NOMINATIM_URL              = "https://tg.jetcab.ru/nomination"





